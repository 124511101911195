import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface IProps {
	label: string;
	desc: string;
}

const TextInfo: React.FC<IProps> = props => {
	const { label, desc } = props;

	const [copied, setCopied] = useState(false);

	const handleCopy = () => {
		navigator.clipboard.writeText(desc);
		setCopied(true);
		setTimeout(() => setCopied(false), 1500);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				borderBottom: '1px solid white'
			}}
		>
			<Typography sx={{ fontWeight: '600', minWidth: '25%' }}>{label}:&nbsp;</Typography>
			<Typography sx={{ fontSize: '1rem' }}>{desc}</Typography>
			<Button onClick={handleCopy}>
				<ContentCopyIcon />
			</Button>
			{copied && <Typography>Copied!</Typography>}
		</Box>
	);
};

export default TextInfo;
