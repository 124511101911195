import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IInitialValue } from './types';

const initialState: IInitialValue = {
	user: null,
	loading: false
};

export const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		startLoading: (state): void => {
			state.loading = true;
		},
		saveCurrentUserSuccess: (state, action: PayloadAction<any>): void => {
			state.user = action.payload;
			state.loading = false;
		},
		saveCurrentUserFailure: (state): void => {
			state.loading = false;
		},
		finishLoading: (state): void => {
			state.loading = false;
		},
		clearCurrentUser: (state): void => {
			state.user = null;
			localStorage.removeItem('accessToken');
		}
	}
});

export const {
	saveCurrentUserFailure,
	saveCurrentUserSuccess,
	startLoading,
	finishLoading,
	clearCurrentUser
} = user.actions;
export default user.reducer;
