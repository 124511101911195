import React, { useEffect } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCurrentUser } from './store/auth/reducer';
import { Dashboard, Donate, Zvit, AdminPage } from './Pages';
import { Routes as RoutesLink } from './constants/routes';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const expiresAt = localStorage.getItem('expiresAt');

    if (token && expiresAt) {
      const expirationTime = parseInt(expiresAt, 10) * 1000; 

      if (Date.now() > expirationTime) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('expiresAt');
        dispatch(clearCurrentUser());
      } else {
        const timeout = expirationTime - Date.now();
        const timerId = setTimeout(() => {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('expiresAt');
          dispatch(clearCurrentUser());
        }, timeout);

        return () => clearTimeout(timerId);
      }
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={RoutesLink.adminPage} element={<AdminPage />} />
        <Route path={RoutesLink.dashboard} element={<Dashboard />} />
        <Route path={RoutesLink.donate} element={<Donate />} />
        <Route path={RoutesLink.zvit} element={<Zvit />} />
        <Route path='*' element={<Navigate to={RoutesLink.dashboard} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;