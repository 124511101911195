import React, { useEffect } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import AddVolunteerForm from './AddVolunteerForm';
import { VolunteerCard } from '../../Shared';
import { IVolunteer } from '../../../store/volunteers/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { volunteers, volunteersLoading } from '../../../store/volunteers/selectors';
import { getVolunteers } from '../../../store/volunteers/sagaActions';

const style = {
	wrapper: {
		display: 'flex',
		marginBottom: '20px',
		justifyContent: 'space-between'
	},
	volonteersWrapper: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginLeft: '20px',
		width: '100%',
		height: '100%',
		padding: '20px 0'
	}
};

const VolonteersBlock: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const volunteersData = useAppSelector(volunteers);
	const loading = useAppSelector(volunteersLoading);

	useEffect(() => {
		dispatch(getVolunteers());
	}, [dispatch]);

	return (
		<Box sx={style.wrapper}>
			<AddVolunteerForm />
			<Box sx={style.volonteersWrapper}>
				{loading ? (
					<Skeleton variant='rectangular' animation='pulse' width={1000} height={700} />
				) : (
					<>
						<Typography
							variant='h5'
							sx={{
								fontWeight: '600',
								fontStyle: 'italic',
								margin: '10px 0 20px 20px',
								width: '100%'
							}}
						>
							Список Волонтерів
						</Typography>
						{volunteersData.map((item: IVolunteer, index) => (
							<VolunteerCard item={item} key={index} size='small' isDeleteBlock />
						))}
					</>
				)}
			</Box>
		</Box>
	);
};

export default VolonteersBlock;
