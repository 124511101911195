import {
	CreateSocialLink,
	DeleteSocialLink,
	GetSocialLinks,
	NewSocialLink,
	SocialLinksTypes
} from './types';

export const getSocialLinks = (): GetSocialLinks => ({
	type: SocialLinksTypes.GET_SOCIAL_LINKS
});

export const deleteSocialLink = (payload: number): DeleteSocialLink => ({
	type: SocialLinksTypes.DELETE_SOCIAL_LINK,
	payload
});

export const createSocialLink = (payload: NewSocialLink): CreateSocialLink => ({
	type: SocialLinksTypes.CREATE_SOCIAL_LINK,
	payload
});
