import React, { useEffect } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import AddDonateForm from './AddDonateForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { donates, donatesLoading } from '../../../store/donates/selectors';
import { deleteDonate, getDonates } from '../../../store/donates/sagaActions';
import { IDonate } from '../../../store/donates/types';
import { SocialButton } from '../../Shared';
import CloseIcon from '@mui/icons-material/Close';

const style = {
	wrapper: {
		display: 'flex',
		marginBottom: '20px',
		width: '100%'
	},
	donatesWrapper: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginLeft: '20px',
		width: '100%',
		height: '100%'
	},
	socialItem: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		alignItems: 'center',
		padding: '2rem 1rem',
		maxHeight: '20rem',
		maxWidth: '10rem',
		wordBreak: 'break-all',
		boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
		margin: '20px',
		borderRadius: '20px',
		height: '50%'
	},
	deleteIcon: {
		position: 'absolute',
		top: '10px',
		right: '10px',
		cursor: 'pointer',
		color: 'red',
		zIndex: 10
	}
};

const DonateBlock: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const donateData = useAppSelector(donates);
	const loading = useAppSelector(donatesLoading);

	const handleDelete = (item: IDonate) => {
		dispatch(deleteDonate(item.id));
	};

	useEffect(() => {
		dispatch(getDonates());
	}, [dispatch]);

	return (
		<Box sx={style.wrapper}>
			<AddDonateForm />
			<Box sx={style.donatesWrapper}>
				{loading ? (
					<Skeleton variant='rectangular' animation='pulse' width={1000} height={350} />
				) : (
					<>
						<Typography
							variant='h5'
							sx={{
								fontWeight: '600',
								fontStyle: 'italic',
								margin: '20px 0 0 20px',
								width: '100%'
							}}
						>
							Список Донатів
						</Typography>
						{donateData.map((item: IDonate, index) => (
							<Box sx={style.socialItem} key={index}>
								<Box sx={style.deleteIcon} onClick={() => handleDelete(item)}>
									<CloseIcon />
								</Box>
								<SocialButton key={item.link} link={item.link} />
								<Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{item.label}</Typography>
								<Typography>{item.link}</Typography>
							</Box>
						))}
					</>
				)}
			</Box>
		</Box>
	);
};

export default DonateBlock;
