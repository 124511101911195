import dataApi from '../api';
import { AxiosResponse } from 'axios';
import { ISocialLink, NewSocialLink } from '../../store/socialLinks/types';

const socialLinks = {
	getSocialLinks: async (): Promise<AxiosResponse<ISocialLink[]>> =>
		dataApi.get('/get-social-links', {
			headers: {
				'ngrok-skip-browser-warning': '69420'
			}
		}),
	deleteSocialLink: (id: number): Promise<AxiosResponse<void>> =>
		dataApi.delete(`/delete-social-link?socialMediaId=${id}`),
	createSocialLink: async (data: NewSocialLink): Promise<AxiosResponse<void>> =>
		dataApi.post('/add-social-link', data)
};

export default socialLinks;
