import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { aboutUsData } from '../../constants/mockData';
import { PhotoArticle, VolunteerCard, Spinner } from '../Shared';
import { getVolunteers } from '../../store/volunteers/sagaActions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { volunteers, volunteersLoading } from '../../store/volunteers/selectors';
import { IVolunteer } from '../../store/volunteers/types';

const Layout: React.FC = () => {
	const dispatch = useAppDispatch();
	const volunteersData = useAppSelector(volunteers);
	const loading = useAppSelector(volunteersLoading);

	useEffect(() => {
		dispatch(getVolunteers());
	}, [dispatch]);


	return (
		<>
			{loading && <Spinner />}
			<Box>
				{aboutUsData.map((item: any, index: number) => (
					<PhotoArticle key={item.id} item={item} index={index} />
				))}
				<Typography sx={{ fontWeight: '600', fontSize: '2rem', paddingBottom: '1rem' }}>
					Наші волонтери
				</Typography>
				<Box sx={{ display: 'flex', flexWrap: 'wrap'}}>
					{volunteersData.map((item: IVolunteer) => (
						<VolunteerCard key={item.id} item={item} />
					))}
				</Box>
			</Box>
		</>
	);
};

export default Layout;
