import { Box, Button, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { Routes, sidebarRoutes } from '../../../constants/routes';
import { KeyboardArrowRight } from '@mui/icons-material';
import SocialButton from '../SocialButton';
import { socialData } from '../../../constants';
import { globalStyles } from '../../../globalStyles';
import { useEffect, useState } from 'react';

interface IProps {
	children?: React.ReactNode;
}

const styles = {
	container: {
		display: 'flex',
		flex: 1,
		boxSizing: 'content-box',
		height: '100vh',
		overflowY: 'hidden'
	},
	sidebar: {
		display: 'flex',
		flexDirection: 'column',
		width: '15rem',
		position: 'relative'
	},
	sidebarHeader: {
		padding: '1rem'
	},
	sidebarHeaderContent: {
		display: 'flex',
		alignItems: 'center'
	},
	headerTitle: {
		fontWeight: '700',
		fontSize: '1.625rem',
		lineHeight: '1',
		textWrap: 'nowrap'
	},
	routeItem: {
		padding: '1rem',
		width: '70%'
	},
	link: {
		textDecoration: 'none',
		padding: '0.5rem',
		borderRadius: '0.5rem',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between'
	},
	linkText: {
		fontSize: '1rem',
		fontWeight: 500,
		margin: '0 0.5rem'
	},
	socialContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		position: 'absolute',
		bottom: '10%',
		width: '100%'
	},
	footerContainer: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		position: 'absolute',
		bottom: '1%',
		width: '100%'
	},
	footerText: {
		color: `${globalStyles.palette.blue}`,
		fontWeight: '600',
		fontSize: '12px'
	},
	footerLink: {
		color: `${globalStyles.palette.yellow}`,
		fontWeight: '600',
		fontSize: '12px'
	},
	content: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: '1rem 2rem',
		backgroundColor: '#FAFBFF',
		overflowY: 'auto'
	},
	contentHeader: {
		height: '40px',
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',

		marginBottom: '20px'
	},
	contentHeaderButton: {
		boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px'
	},
	childrenContainer: {
		maxWidth: '100% !important',
		minWidth: '800px'
	}
};

const ContentWrapper: React.FC<IProps> = (props): JSX.Element => {
	const { children } = props;
	const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('accessToken'));

	const { pathname } = useLocation();

	const handleLogOut = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('expiresAt');
	};

	useEffect(() => {
		const handleStorageChange = () => {
			setIsAuthenticated(!!localStorage.getItem('accessToken'));
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsAuthenticated(!!localStorage.getItem('accessToken'));
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Box sx={styles.container}>
			<Box sx={styles.sidebar}>
				<Box sx={styles.sidebarHeader}>
					<Box sx={styles.sidebarHeaderContent}>
						<Box sx={{ display: 'flex', alignItems: 'end' }}>
							<Typography sx={styles.headerTitle}>Справа Громад</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={{ height: '100%' }}>
					{sidebarRoutes.map(item => (
						<Box key={item.id} sx={styles.routeItem}>
							<Link
								to={item.url}
								style={{
									...styles.link,
									color: pathname.includes(item.url) ? 'white' : 'black',
									backgroundColor: pathname.includes(item.url) ? '#2346A0' : 'none'
								}}
							>
								<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Typography sx={styles.linkText}>{item.label}</Typography>
								</Box>
								<KeyboardArrowRight />
							</Link>
						</Box>
					))}
				</Box>
				<Box sx={styles.socialContainer}>
					{socialData.map((item: any, index) => (
						<SocialButton key={index} icon={<item.icon />} link={item.link} />
					))}
				</Box>
				<Box sx={styles.footerContainer}>
					<Typography sx={styles.footerText}>Powered by</Typography>
					<a
						target='blank'
						href='https://www.linkedin.com/in/dima-sorochynskyi-aa44b5226/'
						style={styles.footerLink}
					>
						Dima Sorochynskyi
					</a>
					<a
						target='blank'
						href='https://www.linkedin.com/in/valery-grinevich-2071261b9/'
						style={styles.footerLink}
					>
						Valery Grinevich
					</a>
				</Box>
			</Box>
			<Box sx={styles.content}>
				{pathname === Routes.adminPage && isAuthenticated && (
					<Box sx={styles.contentHeader}>
						<Button
							onClick={handleLogOut}
							variant='contained'
							color='primary'
							sx={styles.contentHeaderButton}
						>
							Log out
						</Button>
					</Box>
				)}
				<Box sx={styles.childrenContainer}>{children}</Box>
			</Box>
		</Box>
	);
};

export default ContentWrapper;
