import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import VolunteersBlock from './VolonteersBlock';
import NewsBlock from './NewsBlock';
import Login from './Login/Login';
import SocialLinksBlock from './SocialLinksBlock';
import DonateBlock from './DonateBlock';

const Layout: React.FC = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('accessToken'));

	useEffect(() => {
		const handleStorageChange = () => {
			setIsAuthenticated(!!localStorage.getItem('accessToken'));
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsAuthenticated(!!localStorage.getItem('accessToken'));
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<Box>
			{isAuthenticated ? (
				<>
					<VolunteersBlock />
					<NewsBlock />
					<SocialLinksBlock />
					<DonateBlock />
				</>
			) : (
				<Login />
			)}
		</Box>
	);
};

export default Layout;
