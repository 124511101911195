import { combineReducers } from '@reduxjs/toolkit';
import volunteers from './volunteers/reducer';
import donates from './donates/reducer';
import socialLinks from './socialLinks/reducer';
import user from './auth/reducer';


const rootReduser = () =>
	combineReducers({
		volunteers,
		donates,
		socialLinks,
		user,
	});

export default rootReduser;
