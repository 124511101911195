import { startLoading, saveDonatesFailure, saveDonatesSuccess } from './reducer';
import { call, debounce, delay, put } from 'redux-saga/effects';
import apiService from '../../apiServices/requests';
import { DonatesTypes, NewDonate } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export function* getDonates() {
	yield put(startLoading());

	const { data, status } = yield call(apiService.getDonates);

	if (status === 200) {
		yield put(saveDonatesSuccess(data));
	} else {
		yield put(saveDonatesFailure());
	}
}

export function* deleteDonate(action: PayloadAction<number>) {
  yield put(startLoading());
  const { status } = yield call(apiService.deleteDonate, action.payload);

  if (status === 200) {
		yield delay(1000);
    yield call(getDonates);
  } else {
    yield put(saveDonatesFailure());
  }
}

export function* createDonate(action: PayloadAction<NewDonate>) {
	yield put(startLoading());

	const { status } = yield call(apiService.createDonate, action.payload);

	if (status === 201) {
		yield delay(1000);
		yield call(getDonates);
	} else {
		yield put(saveDonatesFailure());
	}
}

export function* watchAllDonates() {
	yield debounce(400, DonatesTypes.GET_DONATES, getDonates);
	yield debounce(400, DonatesTypes.DELETE_DONATE, deleteDonate);
	yield debounce(400, DonatesTypes.CREATE_DONATE, createDonate);
}
