import dataApi from '../api';
import { AxiosResponse } from 'axios';
import { IDonate, NewDonate } from '../../store/donates/types';

const donates = {
	getDonates: async (): Promise<AxiosResponse<IDonate[]>> =>
		dataApi.get('/get-donate-links', {
			headers: {
				'ngrok-skip-browser-warning': '69420'
			}
		}),
	deleteDonate: (id: number): Promise<AxiosResponse<void>> =>
		dataApi.delete(`/delete-donate?donateId=${id}`),
	createDonate: async (data: NewDonate): Promise<AxiosResponse<void>> =>
		dataApi.post('/add-donate', data)
};

export default donates;
