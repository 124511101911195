import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';

const initialState: InitialState = {
	donates: [],
	donatesLoading: false
};

export const donates = createSlice({
	name: 'donates',
	initialState,
	reducers: {
		startLoading: (state): void => {
			state.donatesLoading = true;
		},
		saveDonatesFailure: (state): void => {
			state.donatesLoading = false;
		},
		saveDonatesSuccess: (state, action: PayloadAction<any>): any => {
			state.donates = action.payload;
			state.donatesLoading = false;
		}
	}
});

export const { startLoading, saveDonatesFailure, saveDonatesSuccess } = donates.actions;

export default donates.reducer;
