import React from 'react';
import Layout from '../../Components/AdminPage';
import ContentWrapper from '../../Components/Shared/ContentWrapper';

const AdminPage: React.FC = (): JSX.Element => {
	return (
		<ContentWrapper>
			<Layout />
		</ContentWrapper>
	);
};

export default AdminPage;
