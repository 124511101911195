import { Box, Typography } from '@mui/material';
import LinkButton from '../LinkButton';
import UserAvatar from '../../../static/images/user_avatar.png';
import { IVolunteer } from '../../../store/volunteers/types';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { deleteVolunteer } from '../../../store/volunteers/sagaActions';

interface IProps {
	item: IVolunteer;
	size?: 'small' | 'large';
	isDeleteBlock?: boolean;
}

const VolunteerCard: React.FC<IProps> = ({ item, size = 'large', isDeleteBlock = false }) => {
	const dispatch = useDispatch();

	const handleDelete = () => {
		dispatch(deleteVolunteer(item.id));
	};

	const cardDimensions =
		size === 'small' ? { width: '170px', height: '200px' } : { width: '300px', height: '400px' };
	const imageDimensions =
		size === 'small' ? { width: '170px', height: '170px' } : { width: '300px', height: '300px' };

	const styles = {
		container: {
			display: 'flex',
			justifyContent: 'center',
			margin: '0 0.5rem',
			minWidth: '230px',
			'@media (max-width: 1200px)': {
				width: '30%'
			},
			'@media (max-width: 900px)': {
				width: '45%'
			},
			'@media (max-width: 600px)': {
				width: '100%'
			}
		},
		card: {
			display: 'flex',
			flexDirection: 'column',
			margin: '0 0 2rem',
			justifyContent: 'space-between',
			backgroundColor: '#FFF',
			borderRadius: '1rem',
			boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
			width: cardDimensions.width,
			height: cardDimensions.height,
			position: 'relative',
			overflow: 'hidden'
		},
		deleteIcon: {
			position: 'absolute',
			top: '10px',
			right: '10px',
			cursor: 'pointer',
			color: 'red',
			zIndex: 10
		},
		image: {
			width: imageDimensions.width,
			height: imageDimensions.height,
			objectFit: 'cover',
			objectPosition: 'center'
		},
		infoBox: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			height: '100%',
			position: 'absolute'
		},
		nameText: {
			fontWeight: '600',
			fontSize: '1rem',
			width: '100%',
			backgroundColor: 'whitesmoke',
			textAlign: 'center'
		},
		positionText: {
			fontWeight: '600',
			fontSize: '0.8rem',
			width: '100%',
			backgroundColor: 'whitesmoke',
			textAlign: 'center',
			color: '#2346A0',
			textWrap: 'nowrap'
		}
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.card}>
				{isDeleteBlock && (
					<Box sx={styles.deleteIcon} onClick={handleDelete}>
						<CloseIcon />
					</Box>
				)}
				<Box
					component='img'
					src={item.photo ? `data:image/jpeg;base64,${item.photo}` : UserAvatar}
					alt={item.first_name}
					sx={styles.image}
				/>
				<Box sx={styles.infoBox}>
					<Typography sx={styles.nameText}>{item.first_name + ' ' + item.last_name}</Typography>
					<Typography sx={styles.positionText}>Волонтер</Typography>
					{item.link && (
						<LinkButton
							label='Подивитись профіль'
							link={item.link}
							key={item.id}
							width='100%'
							margin='0'
							isDeleteBlock
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default VolunteerCard;
