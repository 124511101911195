import { Facebook, Instagram, Telegram, YouTube } from '@mui/icons-material';
import DronePhoto1 from '../static/images/drone_1.jpg';
import AboutUs from '../static/images/aboutUs_1.jpg';
import AboutUsLogo from '../static/images/logo_spgr_dark.jpg';
import MascingGrid from '../static/images/mascingGrid.jpg';

const socialData = [
	{
		id: 1,
		icon: Facebook,
		link: 'https://www.facebook.com/spravagromadzp'
	},
	{
		id: 2,
		icon: Instagram,
		link: 'https://www.instagram.com/spgr_zp'
	},
	{
		id: 3,
		icon: Telegram,
		link: 'https://t.me/spgr_zp'
	},
	{
		id: 3,
		icon: YouTube,
		link: 'https://t.me/spgr_zp'
	}
];

const aboutUsData = [
	{
		id: 1,
		photo: AboutUsLogo,
		label: 'Справа громад Запоріжжя',
		text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium'
	},
	{
		id: 2,
		photo: AboutUs,
		label: 'Наша команда',
		text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium'
	},
	{
		id: 3,
		photo: DronePhoto1,
		label: 'Створення дронів',
		text: `Раді повідомити, що завдяки вашій підтримці ми змогли зібрати та відправити 20 FPV-дронів для виконання важливих завдань на передовій. Ці дрони допоможуть нашим захисникам ефективніше виконувати розвідувальні операції, виявляти ворожі позиції та забезпечувати безпеку наших воїнів.

Кожен дрон – це результат спільних зусиль і небайдужості наших волонтерів, донорів та усіх, хто долучився до нашої ініціативи. Завдяки вашій фінансовій підтримці та матеріалам, ми змогли оснастити дрони всім необхідним для виконання їхніх завдань.

Дякуємо всім, хто допомагає нам у цій важливій справі. Разом ми наближаємо перемогу та робимо все можливе для захисту нашої країни. Ваш внесок – це велика допомога для наших захисників.

Слава Україні!`,
		link: 'https://send.monobank.ua/jar/8ci7ubYstt'
	},
	{
		id: 4,
		photo: MascingGrid,
		label: 'Маскувальні сітки',
		text: `
		Ми почали плести нові маскувальні сітки для наших захисників, і нам потрібна ваша допомога! Кожна маскувальна сітка – це маленький вклад в безпеку наших воїнів, і ви можете стати частиною цієї важливої справи.

Нам потрібні матеріали для плетіння: старі речі кольору хакі, зеленого, сірого, коричневого та інших відтінків, які допоможуть створити надійний камуфляж. Якщо у вас є непотрібні футболки, сорочки, штани або інші тканини відповідних кольорів – приносьте їх до нас. Також будемо раді будь-якій фінансовій допомозі для придбання необхідних матеріалів.

Разом ми зможемо зробити більше! Допоможемо нашим захисникам залишатися непомітними та в безпеці. Долучайтеся до нашої ініціативи та робіть свій внесок у спільну перемогу.

Дякуємо за вашу підтримку!`,
		link: 'https://send.monobank.ua/jar/7kMLXgWSaE'
	}
];

const newsData = [
	{
		id: 1,
		created_at: '20.04.2024 18:24',
		photos: [MascingGrid, DronePhoto1, MascingGrid, MascingGrid],
		label: 'Закупівля матеріалів',
		description:
			`Завдяки зібраним коштам волонтерський центр успішно закупив необхідні матеріали для плетіння маскувальних сіток та створення дронів. За останній місяць було зібрано значну суму грошей, яка дозволила нам здійснити ці важливі закупівлі. Зібрані нами гроші дозволили придбати високоякісні тканини та фарби для сіток. Ми також закупили спеціальні нитки та інші необхідні матеріали для виготовлення міцних та надійних маскувальних сіток. Кошти також були витрачені на компоненти для створення сучасних дронів. Всі необхідні електронні деталі, акумулятори та інші складові вже на нашому складі. Завдяки цим дронам, наші військові зможуть більш ефективно виконувати свої завдання. Маскувальні сітки допоможуть забезпечити додатковий захист та прихованість для наших захисників. Ми вдячні всім, хто долучився до збору коштів та підтримує наші ініціативи. Закуплені матеріали вже розподіляються між нашими волонтерами для початку роботи. Разом ми робимо великий внесок у наближення перемоги.`
	},
	{
		id: 2,
		created_at: '20.04.2024 18:24',
		photos: [DronePhoto1],
		label: 'Передали захисникам дрони',
		description:
			'Наш волонтерський центр передав захисникам новітні дрони, створені завдяки зібраним коштам. Ці дрони були виготовлені з використанням сучасних технологій та високоякісних матеріалів. Вони значно підвищать можливості наших військових у розвідці та виконанні бойових завдань. Дрони оснащені камерами високої роздільної здатності, що дозволяє отримувати чіткі зображення в режимі реального часу. Завдяки цьому, наші захисники зможуть оперативніше реагувати на загрози. Також дрони мають функцію автопілоту, що робить їх використання більш ефективним та безпечним. Передача дронів пройшла успішно, і вони вже знаходяться на передовій. Захисники висловили вдячність за цінну допомогу, яка допоможе їм у виконанні бойових завдань. Ми продовжуємо збір коштів для подальшої підтримки наших військових. Ваша допомога робить вагомий внесок у захист нашої країни та наближення перемоги.'
	},
	{
		id: 3,
		created_at: '20.04.2024 18:24',
		photos: [DronePhoto1],
		label: 'Створення дронів',
		description:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur natus vero laboriosam temporibus architecto, quas quasi beatae in, commodi odio veniam deleniti, exercitationem aut labore placeat. Doloribus nulla sint accusantium.',
		link: 'https://send.monobank.ua/jar/8ci7ubYstt'
	}
];

export { socialData, aboutUsData, newsData };
