import { startLoading, saveVolunteersFailure, saveVolunteersSuccess } from './reducer';
import { call, debounce, delay, put } from 'redux-saga/effects';
import apiService from '../../apiServices/requests';
import { NewVolunteer, VolunteersTypes } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export function* getVolunteers() {
	yield put(startLoading());

	const { data, status } = yield call(apiService.getVolunteers);
	if (status === 200) {
		yield put(saveVolunteersSuccess(data));
	} else {
		yield put(saveVolunteersFailure());
	}
}

export function* deleteVolunteer(action: PayloadAction<number>) {
	yield put(startLoading());
	const { status } = yield call(apiService.deleteVolunteer, action.payload);

	if (status === 200) {
		yield delay(1000);
		yield call(getVolunteers);
	} else {
		yield put(saveVolunteersFailure());
	}
}

export function* createVolunteer(action: PayloadAction<NewVolunteer>) {
	yield put(startLoading());

	const { status } = yield call(apiService.createVolunteer, action.payload);

	if (status === 201) {
		yield delay(1000);
		yield call(getVolunteers);
	} else {
		yield put(saveVolunteersFailure());
	}
}

export function* watchAllVolunteers() {
	yield debounce(400, VolunteersTypes.GET_VOLUNTEERS, getVolunteers);
	yield debounce(400, VolunteersTypes.DELETE_VOLUNTEER, deleteVolunteer);
	yield debounce(400, VolunteersTypes.CREATE_VOLUNTEER, createVolunteer);
}
