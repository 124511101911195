import React from "react";
import Layout from "../../Components/Donate";
import ContentWrapper from "../../Components/Shared/ContentWrapper";

const Donate: React.FC = (): JSX.Element => (
  <ContentWrapper>
    <Layout />
  </ContentWrapper>
);

export default Donate;