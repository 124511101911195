import React, { useEffect } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import AddSocialLinksForm from './AddSocialLinksForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { SocialButton } from '../../Shared';
import { socialLinksLoading, socialLinks } from '../../../store/socialLinks/selectors';
import { deleteSocialLink, getSocialLinks } from '../../../store/socialLinks/sagaActions';
import CloseIcon from '@mui/icons-material/Close';
import { ISocialLink } from '../../../store/socialLinks/types';

const style = {
	wrapper: {
		display: 'flex',
		marginBottom: '20px',
		width: '100%'
	},
	socialWrapper: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		marginLeft: '20px'
	},
	socialItem: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		alignItems: 'center',
		padding: '2rem 1rem',
		maxHeight: '20rem',
		maxWidth: '10rem',
		wordBreak: 'break-all',
		boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
		margin: '20px',
		borderRadius: '20px',
		height: '50%'
	},
	deleteIcon: {
		position: 'absolute',
		top: '10px',
		right: '10px',
		cursor: 'pointer',
		color: 'red',
		zIndex: 10
	}
};

const SocialLinksBlock: React.FC = (): JSX.Element => {
	const dispatch = useAppDispatch();
	const socialData = useAppSelector(socialLinks);
	const loading = useAppSelector(socialLinksLoading);

	const handleDelete = (item: ISocialLink) => {
		dispatch(deleteSocialLink(item.id));
	};

	useEffect(() => {
		dispatch(getSocialLinks());
	}, [dispatch]);

	return (
		<Box sx={style.wrapper}>
			<AddSocialLinksForm />
			<Box sx={style.socialWrapper}>
				{loading ? (
					<Skeleton variant='rectangular' animation='pulse' width={1000} height={350} />
				) : (
					<>
						<Typography
							variant='h5'
							sx={{
								fontWeight: '600',
								fontStyle: 'italic',
								margin: '20px 0 0 20px',
								width: '100%'
							}}
						>
							Список Соціальних мереж
						</Typography>
						{socialData.map((item: ISocialLink, index) => (
							<Box sx={style.socialItem} key={index}>
								<Box sx={style.deleteIcon} onClick={() => handleDelete(item)}>
									<CloseIcon />
								</Box>
								<SocialButton key={item.link} link={item.link} />
								<Typography sx={{ fontSize: '1rem', fontWeight: '600' }}>{item.name}</Typography>
								<Typography>{item.link}</Typography>
							</Box>
						))}
					</>
				)}
			</Box>
		</Box>
	);
};

export default SocialLinksBlock;
