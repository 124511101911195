import React from 'react';
import { Button } from '@mui/material';
import { globalStyles } from '../../../globalStyles';

interface IProps {
	label: string;
	link: string;
	color?: string;
	width?: string;
	margin?: string;
	padding?: string;
	isDeleteBlock?: boolean
}

const LinkButton: React.FC<IProps> = props => {
	const { label, link, width, color, margin, padding, isDeleteBlock } = props;

	const openLink = () => {
		window.open(link, '_blank');
	};

	return (
		<Button
			onClick={openLink}
			style={{
				backgroundColor: `${color ? color : globalStyles.palette.blue}`,
				color: `${globalStyles.palette.yellow}`,
				fontWeight: '600',
				fontSize: `${isDeleteBlock ? '0.65rem' : '1rem'}`,
				width: `${width || '45%'}`,
				padding: `${padding || isDeleteBlock ? '0.5rem' : '1rem'}`,
				margin: `${margin || '2rem 0'}`
			}}
		>
			{label}
		</Button>
	);
};

export default LinkButton;
