import { Box, Typography } from '@mui/material';
import { newsData } from '../../constants';
import NewsBlock from './NewsBlock';

const Layout: React.FC = () => {
	return (
		<Box>
			<Typography sx={{ fontWeight: '600', fontSize: '2rem', paddingBottom: '1rem' }}>
				Новини та звіти
			</Typography>
			{newsData.map((item, index) => (
				<NewsBlock item={item} key={index} />
			))}
		</Box>
	);
};

export default Layout;
