import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IVolunteer, InitialState } from './types';

const initialState: InitialState = {
	volunteers: [],
	volunteersLoading: false,
};

export const volunteers = createSlice({
	name: 'volunteers',
	initialState,
	reducers: {
		startLoading: (state): void => {
			state.volunteersLoading = true;
		},
		saveVolunteersFailure: (state): void => {
			state.volunteersLoading = false;
		},
		saveVolunteersSuccess: (state, action: PayloadAction<IVolunteer[]>): void => {
			state.volunteers = action.payload;
			state.volunteersLoading = false;
		}
	}
});

export const { startLoading, saveVolunteersFailure, saveVolunteersSuccess } = volunteers.actions;

export default volunteers.reducer;
