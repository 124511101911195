import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { NewDonate } from '../../../../store/donates/types';
import { createDonate } from '../../../../store/donates/sagaActions';

const validationSchema = Yup.object({
	label: Yup.string().required("Заголовок обов'язковий"),
	link: Yup.string().url('Неправильний формат посилання').required("Посилання обов'язкове")
});

const AddDonationForm: React.FC = (): JSX.Element => {
	const dispatch = useDispatch();

	const onSubmit = (values: NewDonate) => {
		dispatch(createDonate(values));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				minWidth: '20rem',
				maxWidth: '20rem',
				maxHeight: '30rem',
				padding: '2rem',
				backgroundColor: '#EEEEEE',
				borderRadius: '1rem',
				boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px'
			}}
		>
			<Typography
				variant='h5'
				sx={{ fontWeight: '600', fontStyle: 'italic', paddingBottom: '1rem', textAlign: 'center' }}
			>
				Додавання Донатів
			</Typography>
			<Formik
				initialValues={{
					label: '',
					link: ''
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ errors, touched }) => (
					<Form>
						<Box sx={{ marginBottom: '1rem' }}>
							<Field
								name='label'
								as={TextField}
								label='Заголовок'
								variant='outlined'
								margin='normal'
								fullWidth
								error={touched.label && Boolean(errors.label)}
								helperText={touched.label && errors.label}
							/>
						</Box>
						<Box sx={{ marginBottom: '1rem' }}>
							<Field
								name='link'
								as={TextField}
								label='Посилання'
								variant='outlined'
								margin='normal'
								fullWidth
								error={touched.link && Boolean(errors.link)}
								helperText={touched.link && errors.link}
							/>
						</Box>
						<Button variant='contained' color='primary' type='submit' sx={{ marginTop: '1rem' }}>
							Відправити
						</Button>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default AddDonationForm;
