import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { donates, donatesLoading } from '../../store/donates/selectors';
import { getDonates } from '../../store/donates/sagaActions';
import { LinkButton, Spinner, TextInfo } from '../Shared';
import { IDonate } from '../../store/donates/types';

const Layout: React.FC = () => {
	const dispatch = useAppDispatch();
	const donatesData = useAppSelector(donates);
	const loading = useAppSelector(donatesLoading);

	useEffect(() => {
		dispatch(getDonates());
	}, [dispatch]);

	return (
		<>
			{loading && <Spinner />}
			<Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '2rem',
						margin: '0 0 2rem',
						backgroundColor: '#EEEEEE',
						borderRadius: '3rem',
						boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px'
					}}
				>
					<Box
						sx={{
							width: '30%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-around'
						}}
					>
						<Typography sx={{ fontWeight: '600', fontStyle: 'italic', paddingBottom: '0.5rem' }}>
							Ви, напевно, чули про необхідність дронів та маскуючих сіток для наших військових, які
							захищають нашу країну.
						</Typography>
						<Typography sx={{ fontWeight: '600', fontStyle: 'italic' }}>
							Якщо ви хочете допомогти нашим захисникам у їхній важливій місії, ми закликаємо вас
							підтримати наші зусилля шляхом пожертвування. Ваш внесок допоможе забезпечити наші
							війська необхідними засобами спостереження та захисту, допомагаючи зберегти безпеку та
							мир на наших землях.
						</Typography>
						<Typography sx={{ fontWeight: '600', fontStyle: 'italic', fontSize: '1.5rem' }}>
							Дякуємо за вашу підтримку!
						</Typography>
					</Box>
					<Box sx={{ width: '65%' }}>
						<Box sx={{ marginBottom: '1rem' }}>
							<Typography
								sx={{
									fontSize: '1.25rem',
									fontWeight: '600',
									fontStyle: 'italic',
									paddingBottom: '1rem'
								}}
							>
								Приватбанк
							</Typography>
							<TextInfo label='Номер картки' desc='4246001002717001' />
						</Box>
						<Box sx={{ marginBottom: '1rem' }}>
							<Typography
								sx={{
									fontSize: '1.25rem',
									fontWeight: '600',
									fontStyle: 'italic',
									paddingBottom: '1rem'
								}}
							>
								Для ІНШИХ банків:
							</Typography>
							<TextInfo
								label='Найменування отримувача'
								desc='Ф-Я ВІДОКРЕМЛЕНИЙ ПІДРОЗДІЛ "ЗАПОРІЗЬКА АСОЦІАЦІЯ ТЕРИТОРІАЛЬНИХ ГРОМАД ГО СОЛІДАРНА СПРАВА ГРОМАД"'
							/>
							<TextInfo label='Код ЄДРПОУ/ІПНа' desc='44165870' />
							<TextInfo label='IBAN РАХУНОК' desc='UA203133990000026006010202237' />
							<TextInfo label='Назва банку' desc='ЗАПОРIЗЬКЕ РУ АТ КБ "ПРИВАТБАНК"' />
							<TextInfo
								label='Призначення платежу'
								desc='благодійна безповоротна допомога на користь ГО'
							/>
						</Box>
					</Box>
				</Box>
				<Box
					sx={{
						padding: '2rem',
						backgroundColor: '#EEEEEE',
						borderRadius: '3rem',
						boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
						minHeight: '20rem'
					}}
				>
					<Typography sx={{ fontSize: '2rem' }}>Лінки на донати</Typography>
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'space-around'
						}}
					>
						{donatesData.map((item: IDonate) => (
							<LinkButton key={item.id} label={item.label} link={item.link} padding='2rem' />
						))}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Layout;
