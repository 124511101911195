import { startLoading, saveSocialLinksFailure, saveSocialLinksSuccess } from './reducer';
import { call, debounce, delay, put } from 'redux-saga/effects';
import apiService from '../../apiServices/requests';
import { NewSocialLink, SocialLinksTypes } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

export function* getSocialLinks() {
	yield put(startLoading());

	const { data } = yield call(apiService.getSocialLinks);

	if (data.length) {
		yield put(saveSocialLinksSuccess(data));
	} else {
		yield put(saveSocialLinksFailure());
	}
}

export function* deleteSocialLink(action: PayloadAction<number>) {
	yield put(startLoading());
	const { status } = yield call(apiService.deleteSocialLink, action.payload);

	if (status === 200) {
		yield delay(1000);
		yield call(getSocialLinks);
	} else {
		yield put(saveSocialLinksFailure());
	}
}

export function* createSocialLink(action: PayloadAction<NewSocialLink>) {
	yield put(startLoading());

	const { status } = yield call(apiService.createSocialLink, action.payload);

	if (status === 201) {
		yield delay(1000);
		yield call(getSocialLinks);
	} else {
		yield put(saveSocialLinksFailure());
	}
}

export function* watchAllSocialLinks() {
	yield debounce(400, SocialLinksTypes.GET_SOCIAL_LINKS, getSocialLinks);
	yield debounce(400, SocialLinksTypes.DELETE_SOCIAL_LINK, deleteSocialLink);
	yield debounce(400, SocialLinksTypes.CREATE_SOCIAL_LINK, createSocialLink);
}
