import dataApi from '../api';
import { AxiosResponse } from 'axios';
import { IVolunteer, NewVolunteer } from '../../store/volunteers/types';

const volunteers = {
	getVolunteers: async (): Promise<AxiosResponse<IVolunteer[]>> =>
		dataApi.get('/get-volunteers', {
			headers: {
				'ngrok-skip-browser-warning': '69420'
			}
		}),
	deleteVolunteer: async (id: number): Promise<AxiosResponse<void>> =>
		dataApi.delete(`/delete-volunteer?volunteerId=${id}`),
	createVolunteer: async (data: NewVolunteer): Promise<AxiosResponse<void>> =>
		dataApi.post('/add-volunteer', data)
};

export default volunteers;
