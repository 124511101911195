export enum DonatesTypes {
	GET_DONATES = 'GET_DONATES',
	DELETE_DONATE = 'DELETE_DONATE',
	CREATE_DONATE = 'CREATE_DONATE'
}

export interface InitialState {
	donates: IDonate[];
	donatesLoading: boolean;
}

export interface IDonate {
	id: number;
	label: string;
	link: string;
}

export type NewDonate = Omit<IDonate, 'id'>;

export type GetDonates = {
	type: typeof DonatesTypes.GET_DONATES;
};

export type DeleteDonate = {
	type: typeof DonatesTypes.DELETE_DONATE;
	payload: number;
}

export type CreateDonate = {
	type: typeof DonatesTypes.CREATE_DONATE;
	payload: NewDonate;
}
