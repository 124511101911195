import { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const Spinner: React.FC = () => {
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowMessage(true);
		}, 15000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'fixed',
				zIndex: 999,
				backgroundColor: 'whitesmoke',
				opacity: '80%',
				top: '0'
			}}
		>
			<CircularProgress size='5rem' color='primary' />
			{showMessage && (
				<Typography sx={{ paddingTop: '2rem', fontSize: '2rem' }}>
					Сайт тимчасово недоступний. Спробуйте пізніше.
				</Typography>
			)}
		</Box>
	);
};

export default Spinner;
