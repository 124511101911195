import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, Box, Container, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { loginUser } from '../../../store/auth/sagaActions';
import { ILoginData } from '../../../constants/types/user';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Required'),
});

const Login: React.FC = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values: ILoginData) => {
    dispatch(loginUser(values));
  };

  const handleRegistration = () => {
    
  }

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <Typography component="h1" variant="h5">
        Login
      </Typography>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Box sx={{ mt: 1 }}>
              <Field
                name="email"
                as={TextField}
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              <Field
                name="password"
                as={TextField}
                label="Password"
                type="password"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
              <Button
                type="button"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                onClick={handleRegistration}
              >
                or Registration 
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Login;