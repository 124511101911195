import React from 'react';
import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import { LinkButton } from '../../Shared';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
	item: {
		label: string;
		description: string;
		photos: any[];
		link?: string;
		created_at: string;
	};
	short?: boolean;
	size?: 'small' | 'large';
	isDeleteBlock?: boolean;
}

const styles = {
	container: (size: 'small' | 'large') => ({
		width: '100%',
		padding: '1rem',
		marginBottom: size === 'small' ? '1rem' : '2rem',
		backgroundColor: '#FFF',
		borderRadius: size === 'small' ? '1.5rem' : '3rem',
		boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
		position: 'relative'
	}),
	imageList: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		maxHeight: '300px',
		overflow: 'hidden'
	},
	imageListItem: {
		width: 'calc(24% - 8px)',
		height: '300px',
		borderRadius: '3rem',
		margin: '4px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden'
	},
	image: {
		width: '100%',
		height: '290px',
		borderRadius: '3rem'
	}
};

const NewsBlock: React.FC<IProps> = ({ item, short, size = 'large', isDeleteBlock = false }) => {
	const description = short ? item.description.slice(0, 150) : item.description;

	const handleDelete = () => {
		console.log('Deleting news...', item);
	};

	return (
		<Box sx={styles.container(size)}>
			{isDeleteBlock && (
				<Box
					sx={{
						position: 'absolute',
						top: '10px',
						right: '10px',
						cursor: 'pointer',
						color: 'red',
						zIndex: 10
					}}
					onClick={handleDelete}
				>
					<CloseIcon />
				</Box>
			)}
			<Box sx={{ padding: size === 'small' ? '1rem' : '2rem' }}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography sx={{ fontSize: size === 'small' ? '1rem' : '1.5rem', fontWeight: '600' }}>
						{item.label}
					</Typography>
					<Typography sx={{ fontSize: '0.75rem', fontWeight: '600', color: 'grey' }}>
						{item.created_at}
					</Typography>
				</Box>
				<Typography>{description}...</Typography>
				<ImageList sx={styles.imageList} variant='standard' cols={4}>
					{item.photos.map((photo, index) => (
						<ImageListItem key={index} sx={styles.imageListItem}>
							<img src={photo} alt={`${index}`} style={styles.image} />
						</ImageListItem>
					))}
				</ImageList>
				{item.link && <LinkButton label='Детальніше' link={item.link} />}
			</Box>
		</Box>
	);
};

export default NewsBlock;
