import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createVolunteer } from '../../../../store/volunteers/sagaActions';
import { NewVolunteer } from '../../../../store/volunteers/types';
import { UploadPhoto } from '../../../Shared';

const validationSchema = Yup.object({
	first_name: Yup.string().required("Ім'я обов'язкове"),
	last_name: Yup.string().required("Прізвище обов'язкове"),
	position: Yup.string().required("Посада обов'язкова"),
	link: Yup.string().url('Неправильний формат посилання').required("Посилання обов'язкове")
});

const AddVolonteerForm: React.FC = (): JSX.Element => {
	const dispatch = useDispatch();

	const onSubmit = (values: NewVolunteer) => {
		dispatch(createVolunteer(values));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				width: '100%',
				minWidth: '20rem',
				maxWidth: '22rem',
				maxHeight: '50rem',
				padding: '2rem',
				backgroundColor: '#EEEEEE',
				borderRadius: '1rem',
				boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px'
			}}
		>
			<Typography
				variant='h5'
				sx={{ fontWeight: '600', fontStyle: 'italic', paddingBottom: '1rem', textAlign: 'center' }}
			>
				Додавання Волонтерів
			</Typography>
			<Formik
				initialValues={{
					first_name: '',
					last_name: '',
					position: '',
					link: '',
					photo: null
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ errors, touched, setFieldValue }) => (
					<Form>
						<Box sx={{ marginBottom: '1rem' }}>
							<Field
								name='first_name'
								as={TextField}
								label="Ім'я"
								variant='outlined'
								margin='normal'
								fullWidth
								error={touched.first_name && Boolean(errors.first_name)}
								helperText={touched.first_name && errors.first_name}
							/>
						</Box>
						<Box sx={{ marginBottom: '1rem' }}>
							<Field
								name='last_name'
								as={TextField}
								label='Прізвище'
								variant='outlined'
								margin='normal'
								fullWidth
								error={touched.last_name && Boolean(errors.last_name)}
								helperText={touched.last_name && errors.last_name}
							/>
						</Box>
						<Box sx={{ marginBottom: '1rem' }}>
							<Field
								name='position'
								as={TextField}
								label='Посада'
								variant='outlined'
								margin='normal'
								fullWidth
								error={touched.position && Boolean(errors.position)}
								helperText={touched.position && errors.position}
							/>
						</Box>
						<Box sx={{ marginBottom: '1rem' }}>
							<Field
								name='link'
								as={TextField}
								label='Посилання на соціальні мережі'
								variant='outlined'
								margin='normal'
								fullWidth
								error={touched.link && Boolean(errors.link)}
								helperText={touched.link && errors.link}
							/>
						</Box>
						<UploadPhoto setFieldValue={setFieldValue} />
						<Button variant='contained' color='primary' type='submit' sx={{ marginTop: '1rem' }}>
							Відправити
						</Button>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default AddVolonteerForm;
