import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { NewSocialLink } from '../../../../store/socialLinks/types';
import { createSocialLink } from '../../../../store/socialLinks/sagaActions';

const validationSchema = Yup.object({
  name: Yup.string().required("Назва обов'язкова"),
  link: Yup.string().url('Неправильний формат посилання').required("Посилання обов'язкове")
});

const AddSocialLinkForm: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();

	const onSubmit = (values: NewSocialLink) => {
		dispatch(createSocialLink(values))
	};
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: '20rem',
        maxWidth: '20rem',
        maxHeight: '30rem',
        padding: '2rem',
        backgroundColor: '#EEEEEE',
        borderRadius: '1rem',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px'
      }}
    >
      <Typography
        variant='h5'
        sx={{ fontWeight: '600', fontStyle: 'italic', paddingBottom: '1rem', textAlign: 'center' }}
      >
        Додавання Соціальних мереж
      </Typography>
      <Formik
        initialValues={{
          name: '',
          link: ''
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Box sx={{ marginBottom: '1rem' }}>
              <Field
                name='name'
                as={TextField}
                label='Назва соціальної мережі'
                variant='outlined'
                margin='normal'
                fullWidth
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <Field
                name='link'
                as={TextField}
                label='Посилання'
                variant='outlined'
                margin='normal'
                fullWidth
                error={touched.link && Boolean(errors.link)}
                helperText={touched.link && errors.link}
              />
            </Box>
            <Button variant='contained' color='primary' type='submit' sx={{ marginTop: '1rem' }}>
              Відправити
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddSocialLinkForm;
