import volunteers from "./volunteers";
import donates from './donates';
import socialLinks from './socialLinks'
import auth from './auth';


const apiService = {
  ...volunteers,
  ...donates,
  ...socialLinks,
  ...auth,
};
export default apiService;
