export enum VolunteersTypes {
	GET_VOLUNTEERS = 'GET_VOLUNTEERS',
	DELETE_VOLUNTEER = 'DELETE_VOLUNTEER',
	CREATE_VOLUNTEER = 'CREATE_VOLUNTEER'
}

export interface InitialState {
	volunteers: IVolunteer[];
	volunteersLoading: boolean;
}

export interface IVolunteer {
	id: number;
	first_name: string;
	last_name: string;
	position: string;
	photo: string | null;
	link: string | null;
}

export type NewVolunteer = Omit<IVolunteer, 'id'>;

export type GetVolunteers = {
	type: typeof VolunteersTypes.GET_VOLUNTEERS;
};

export type DeleteVolunteer = {
	type: typeof VolunteersTypes.DELETE_VOLUNTEER;
	payload: number;
}

export type CreateVolunteer = {
	type: typeof VolunteersTypes.CREATE_VOLUNTEER;
	payload: NewVolunteer;
}