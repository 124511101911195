import React from 'react';
import { Button } from '@mui/material';
import { globalStyles } from '../../../globalStyles';
import LinkIcon from '@mui/icons-material/Link';

interface IProps {
	icon?: any;
	link: string;
}

const SocialButton: React.FC<IProps> = props => {
	const { icon, link } = props;

	const openLink = () => {
		window.open(link, '_blank');
	};

	return (
		<Button
			onClick={openLink}
			style={{
				width: '2.5rem',
				height: '2.5rem',
				backgroundColor: `${globalStyles.palette.blue}`,
				borderRadius: '50%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				color: `${globalStyles.palette.yellow}`,
				minWidth: '2.5rem'
			}}
		>
			{icon || <LinkIcon/>}
		</Button>
	);
};

export default SocialButton;
