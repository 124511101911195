import { Box, Typography } from '@mui/material';
import LinkButton from '../LinkButton';

interface IProps {
	item: any;
	index: number;
}

const PhotoArticle: React.FC<IProps> = props => {
	const { item, index } = props;

	return (
		<Box
			sx={{
				display: 'flex',
				padding: '2rem',
				margin: '0 0 2rem',
				justifyContent: 'space-between',
				backgroundColor: '#FFF',
				borderRadius: '3rem',
				boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px'
			}}
		>
			{index % 2 === 0 ? (
				<>
					<Box
						sx={{
							width: '60%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							height: '100%',
							alignItems: 'flex-start'
						}}
					>
						<Typography sx={{ fontWeight: '600', fontSize: '1.5rem', paddingBottom: '1rem' }}>
							{item.label}
						</Typography>
						<Typography sx={{ paddingBottom: '1rem' }}>{item.text}</Typography>
						{item.link && <LinkButton label='Зробити донат' link={item.link} key={item.id} />}
					</Box>
					<Box
						sx={{
							width: '600px',
							height: '350px',
							borderRadius: '3rem',
							backgroundImage: `url(${item.photo})`,
							backgroundPosition: 'center',
							backgroundSize: 'cover',
							marginLeft: '5rem'
						}}
					/>
				</>
			) : (
				<>
					<Box
						sx={{
							width: '600px',
							height: '350px',
							borderRadius: '3rem',
							backgroundImage: `url(${item.photo})`,
							backgroundPosition: 'center',
							backgroundSize: 'cover',
							marginRight: '5rem'
						}}
					/>
					<Box
						sx={{
							width: '60%',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-end',
							height: '100%'
						}}
					>
						<Typography sx={{ fontWeight: '600', fontSize: '1.5rem', paddingBottom: '1rem' }}>
							{item.label}
						</Typography>
						<Typography sx={{ paddingBottom: '1rem' }}>{item.text}</Typography>
						{item.link && <LinkButton label='Зробити донат' link={item.link} key={item.id} />}
					</Box>
				</>
			)}
		</Box>
	);
};

export default PhotoArticle;
