enum Routes {
	dashboard = '/dashboard',
	donate = '/donate',
	zvit = '/zvit',
	adminPage = '/admin'
}

const sidebarRoutes = [
	{
		id: 1,
		label: 'Головна',
		url: Routes.dashboard
	},
	{
		id: 2,
		label: 'Донати',
		url: Routes.donate
	},
	{
		id: 3,
		label: 'Новини та звіти',
		url: Routes.zvit
	}
];

export { Routes, sidebarRoutes };
