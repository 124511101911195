import { ILoginData } from '../../constants/types/user';

export enum UserTypes {
	LOGIN_USER = 'LOGIN_USER'
}

export interface IInitialValue {
	user: any;
	loading: boolean;
}

export interface ILoginUser {
  type: typeof UserTypes.LOGIN_USER;
  payload: ILoginData;
}
