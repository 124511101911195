import React from 'react';
import Layout from '../../Components/Dashboard';
import ContentWrapper from '../../Components/Shared/ContentWrapper';

const Dashboard: React.FC = (): JSX.Element => (
	<ContentWrapper>
		<Layout />
	</ContentWrapper>
);

export default Dashboard;
