export const API_DOMAINS = 'https://1452574.yach.web.hosting-test.net/public-organization/public/api';

export enum ResponseCode {
	serverError = 500,
	notFound = 404,
	unauthorized = 401,
	success = 200,
	badRequest = 404,
	found = 302,
	forbidden = 403
}

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
