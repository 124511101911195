import { takeLatest, call, put } from 'redux-saga/effects';
import { UserTypes } from './types';
import apiService from '../../apiServices/requests';
import { PayloadAction } from '@reduxjs/toolkit';
import { ILoginData } from '../../constants/types/user';
import { saveCurrentUserFailure, saveCurrentUserSuccess, startLoading } from './reducer';

export function* loginUser(action: PayloadAction<ILoginData>) {
	yield put(startLoading());

	const { data, status } = yield call(apiService.loginUser, action.payload);

	if (status === 200) {
		yield put(saveCurrentUserSuccess(data));
		localStorage.setItem('accessToken', data.access_token);
		localStorage.setItem('expiresAt', data.expires_in.toString());
	} else {
		yield put(saveCurrentUserFailure());
	}
}

export function* watchAllAuth() {
	yield takeLatest(UserTypes.LOGIN_USER, loginUser);
}
