import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { Formik, Field, Form } from 'formik';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  label: Yup.string().required("Заголовок обов'язковий"),
  description: Yup.string().required("Текст до новини обов'язковий"),
  link: Yup.string().url("Неправильний формат посилання").required("Посилання обов'язкове"),
});

const AddNewsForm: React.FC = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '2rem',
        margin: '0 auto 20px',
        backgroundColor: '#EEEEEE',
        borderRadius: '1rem',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
      }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: '600', fontStyle: 'italic', paddingBottom: '1rem', textAlign: 'center' }}
      >
        Додавання Новини
      </Typography>
      <Formik
        initialValues={{
          label: '',
          description: '',
          link: '',
          photo: '',
        }}
        validationSchema={validationSchema}
        onSubmit={values => {
          console.log(values);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Box sx={{ marginBottom: '1rem' }}>
              <Field
                name="label"
                as={TextField}
                label="Заголовок"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.label && Boolean(errors.label)}
                helperText={touched.label && errors.label}
              />
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <Field
                name="description"
                as={TextField}
                label="Текст до новини"
                variant="outlined"
                margin="normal"
                multiline
                rows={4}
                fullWidth
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <Field
                name="link"
                as={TextField}
                label="Посилання банку для донатів або на інший ресурс"
                variant="outlined"
                margin="normal"
                fullWidth
                error={touched.link && Boolean(errors.link)}
                helperText={touched.link && errors.link}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem',
                margin: '1rem 0',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
              }}
            >
              <Typography>Upload Photo</Typography>
              <CloudUploadIcon sx={{ fontSize: '3rem', margin: '1rem 0' }} />
              <Typography>or drop file</Typography>
            </Box>
            <Button variant="contained" color="primary" type="submit" sx={{ marginTop: '1rem' }}>
              Відправити
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddNewsForm;
