import {
	CreateVolunteer,
	DeleteVolunteer,
	GetVolunteers,
	NewVolunteer,
	VolunteersTypes
} from './types';

export const getVolunteers = (): GetVolunteers => ({
	type: VolunteersTypes.GET_VOLUNTEERS
});

export const deleteVolunteer = (payload: number): DeleteVolunteer => ({
	type: VolunteersTypes.DELETE_VOLUNTEER,
	payload
});

export const createVolunteer = (payload: NewVolunteer): CreateVolunteer => ({
	type: VolunteersTypes.CREATE_VOLUNTEER,
	payload
});
