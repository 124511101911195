import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormikProps } from 'formik';

interface UploadPhotoProps {
  maxFiles?: number;
  setFieldValue: FormikProps<any>['setFieldValue'];
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({ maxFiles = 1, setFieldValue }) => {
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles = [...files, ...acceptedFiles].slice(0, maxFiles);
    setFiles(newFiles);
    setFieldValue('photo', newFiles[0]);
  }, [files, maxFiles, setFieldValue]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    maxFiles
  });

  const handleRemoveFile = (file: File) => {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
    setFieldValue('photo', newFiles[0] || null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2rem',
        margin: '1rem 0',
        backgroundColor: 'white',
        borderRadius: '0.5rem',
        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <div {...getRootProps()} style={{ textAlign: 'center', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <Typography>Upload Photo</Typography>
        <CloudUploadIcon sx={{ fontSize: '3rem', margin: '1rem 0' }} />
        <Typography>or drop file</Typography>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '1rem',
          justifyContent: 'center',
        }}
      >
        {files.map((file, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              margin: '0.5rem',
              width: '100px',
              height: '100px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              overflow: 'hidden',
              textAlign: 'center',
            }}
          >
            <img
              src={URL.createObjectURL(file)}
              alt="preview"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <IconButton
              onClick={() => handleRemoveFile(file)}
              sx={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                color: 'red',
                backgroundColor: 'white',
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default UploadPhoto;
