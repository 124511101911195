import React from 'react';
import { Box } from '@mui/material';
import AddNewsForm from './AddNewsForm';
import NewsBlock from '../../Zvit/NewsBlock';
import { newsData } from '../../../constants';

const style= {
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },

}


const NewsBlockWrapper: React.FC = (): JSX.Element => {

	return (
    <Box sx={style.wrapper}>
      <AddNewsForm />
			{newsData.map((item, index) => (
				<NewsBlock item={item} key={index} short isDeleteBlock size='small'/>
			))}
    </Box>
	);
};

export default NewsBlockWrapper;

