import { ILoginData } from '../../constants/types/user';
import dataApi from '../api';
import { AxiosResponse } from 'axios';

const auth = {
	loginUser: async (loginData: ILoginData): Promise<AxiosResponse<any>> =>
		dataApi.post('/auth/login', loginData)
};

export default auth;
