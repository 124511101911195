import { GetDonates, DonatesTypes, NewDonate, DeleteDonate, CreateDonate } from './types';

export const getDonates = (): GetDonates => ({
	type: DonatesTypes.GET_DONATES
});

export const deleteDonate = (payload: number): DeleteDonate => ({
	type: DonatesTypes.DELETE_DONATE,
	payload
});

export const createDonate = (payload: NewDonate): CreateDonate => ({
	type: DonatesTypes.CREATE_DONATE,
	payload
});
