export enum SocialLinksTypes {
	GET_SOCIAL_LINKS = 'GET_SOCIAL_LINKS',
	DELETE_SOCIAL_LINK = 'DELETE_SOCIAL_LINK',
	CREATE_SOCIAL_LINK = 'CREATE_SOCIAL_LINK'
}

export interface InitialState {
	socialLinks: ISocialLink[];
	socialLinksLoading: boolean;
}

export interface ISocialLink {
	id: number;
	name: string;
	link: string;
}

export type NewSocialLink = Omit<ISocialLink, 'id'>;

export type GetSocialLinks = {
	type: typeof SocialLinksTypes.GET_SOCIAL_LINKS;
};

export type DeleteSocialLink = {
	type: typeof SocialLinksTypes.DELETE_SOCIAL_LINK;
	payload: number;
}

export type CreateSocialLink = {
	type: typeof SocialLinksTypes.CREATE_SOCIAL_LINK;
	payload: NewSocialLink;
}