import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';

const initialState: InitialState = {
	socialLinks: [],
	socialLinksLoading: false
};

export const socialLinks = createSlice({
	name: 'socialLinks',
	initialState,
	reducers: {
		startLoading: (state): void => {
			state.socialLinksLoading = true;
		},
		saveSocialLinksFailure: (state): void => {
			state.socialLinksLoading = false;
		},
		saveSocialLinksSuccess: (state, action: PayloadAction<any>): any => {
			state.socialLinks = action.payload;
			state.socialLinksLoading = false;
		}
	}
});

export const { startLoading, saveSocialLinksFailure, saveSocialLinksSuccess } = socialLinks.actions;

export default socialLinks.reducer;
